// src/App.js

import React from 'react';
import ProjectsTable from './components/ProjectsTable';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <ProjectsTable />
      </header>
    </div>
  );
}

export default App;
