import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTable, usePagination, useSortBy } from 'react-table';
import '../TableStyles.css';
import {
  Paper, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Container,
  CircularProgress,
  Button,
  TextField,
  MenuItem,
  Select
} from '@mui/material';
import { openDB } from 'idb';

const LoadingIndicator = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 200
    }}
  >
    <CircularProgress />
  </Box>
);

function formatCurrency(number) {
  return '$ ' + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const formatDate = (dateString) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  };
  return new Date(dateString).toLocaleString('es-ES', options);
}

const ProjectsTable = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('desc');

  const fetchData = async () => {
    setLoading(true);
    try {      
      const response = await axios.get(`https://checkstorev2.cheaperlandsales.com/notify/getProducts`);
      const newData = response.data;
      setData(newData);
    } catch (error) {
      console.error('Error fetching data:', error.message);
      alert('Error al obtener los datos. Por favor, intenta de nuevo.');
    } finally {
      setLoading(false); 
    }
  };

  const filteredData = useMemo(() => {
    return data
      .filter((item) => 
        item.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.store.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        const dateDiff = new Date(b.updated_at) - new Date(a.updated_at);
        if (dateDiff !== 0) {
          return dateDiff; // Si las fechas son diferentes, ordena por fecha
        }
        // Si las fechas son iguales, ordena por porcentaje de descuento
        return sortOption === 'asc' ? a.percDisc - b.percDisc : b.percDisc - a.percDisc;
      });
  }, [data, searchTerm, sortOption]);

  const columns = useMemo(
    () => [
      {
        Header: 'Producto',
        accessor: 'image',
        Cell: ({ value, row }) => (
          <div
            onMouseEnter={() => setHoveredImage(row._id)}
            onMouseLeave={() => setHoveredImage(null)}
            onClick={() => setHoveredImage(row._id)}
            style={{ position: 'relative', cursor: 'pointer', width: 128, height: 128, overflow: 'hidden' }}
          >
            <img src={value} alt="Product" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </div>
        ),
      },
      {
        Header: 'Nombre',
        accessor: 'description',
        Cell: ({ value }) => (
          <div
            style={{
              width: '150px', 
              whiteSpace: 'normal', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis', 
              display: '-webkit-box',
              WebkitLineClamp: 3, 
              WebkitBoxOrient: 'vertical',
            }}
          >
            {value.length > 250 ? value.slice(0, 250) + '...' : value}
          </div>
        ),
      },
      {
        Header: 'Descuento',
        accessor: 'percDisc',
        Cell: ({ value }) => `${value} %`,
      },
      {
        Header: 'Precio Tarjeta',
        accessor: 'priceCard',
        Cell: ({ value }) => `${formatCurrency(value)}`,
      },
      {
        Header: 'Precio Actual',
        accessor: 'priceNow',
        Cell: ({ value }) => `${formatCurrency(value)}`,
      },
      {
        Header: 'Precio Anterior',
        accessor: 'priceBefore',
        Cell: ({ value }) => `${formatCurrency(value)}`,
      },
      {
        Header: 'Tienda',
        accessor: 'store',
        Cell: ({ value }) => value,
      },
      {
        Header: 'id',
        accessor: '_id',
        Cell: ({ value }) => value,
      },
      {
        Header: 'Última Actualización',
        accessor: 'updated_at',
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: 'Acción',
        accessor: 'url',
        Cell: ({ value }) => (
          <a href={value} target="_blank" rel="noopener noreferrer">
            <Button variant="contained" color="primary">
              Comprar
            </Button>
          </a>
        ),
      },
    ],
    [hoveredImage]
  );

  const tableInstance = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  return (
    <Container maxWidth="lg" sx={{ padding: '16px', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom align="center">
        Lista de Productos
      </Typography>
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          label="Buscar Producto"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Box sx={{ textAlign: 'center', display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={fetchData}
            disabled={loading}
          >
            {loading ? 'Cargando...' : 'Analizar'}
          </Button>
          <Select
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Ordenar por' }}
          >
            <MenuItem value="desc">Porcentaje: Mayor a Menor</MenuItem>
            <MenuItem value="asc">Porcentaje: Menor a Mayor</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box sx={{ textAlign: 'right', display: 'flex', gap: 3 }}>
        <Typography>
          Página{' '}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>
        </Typography>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Anterior
        </Button>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          Siguiente
        </Button>
      </Box>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <TableContainer component={Paper}>
          <Table {...getTableProps()} size="small">
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()} style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      {...column.getHeaderProps()}
                      sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}
                    >
                      {column.render('Header')}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <TableCell
                        {...cell.getCellProps()}
                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box sx={{ marginTop: 2, textAlign: 'center' }}>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Anterior
        </Button>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          Siguiente
        </Button>
      </Box>
      <Box sx={{ marginTop: 2, textAlign: 'center' }}>
        <span>
          Página{' '}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Ir a la página:{' '}
          <TextField
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(pageNumber);
            }}
            sx={{ width: '50px', mx: 1 }}
          />
        </span>
        <TextField
          select
          label="Tamaño de Página"
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
          variant="outlined"
          sx={{ width: '150px' }}
        >
          {[10, 20, 30, 40, 50].map((size) => (
            <MenuItem key={size} value={size}>
              Mostrar {size}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </Container>
  );
};

export default ProjectsTable;